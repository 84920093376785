import React, { useEffect } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import { usePrismicPreview } from "gatsby-source-prismic"

import { Spinner } from "../components/common/spinner"

import styled from "styled-components"

const StyledPreview = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  > div {
    text-align: center;
  }
`

const PreviewPage = ({ location }) => {
  const { allPrismicDigitalPitchDeck } = useStaticQuery(graphql`
    {
      allPrismicDigitalPitchDeck {
        nodes {
          uid
        }
      }
    }
  `)

  const pubishedUIDs = allPrismicDigitalPitchDeck.nodes.map(node => node.uid)
  // const caseUIDs = allPrismicCaseStudy.nodes.map(node => node.uid)
  // const blogUIDs = allPrismicBlogPost.nodes.map(node => node.uid)

  // const pubishedUIDs = pageUIDs.concat(caseUIDs, blogUIDs)

  const getPagePath = doc => {
    switch (doc.type) {
      case "digital_pitch_deck":
        return `/${doc.uid}`
      default:
        return `/${doc.uid}`
    }
  }

  const pathResolver = () => doc => {
    const { uid, type } = doc
    const previewedUID = uid
    const path = getPagePath(doc)

    if (type === "home_page") {
      return "/"
    } else {
      if (pubishedUIDs.includes(previewedUID)) {
        return path
      } else {
        return `/app/unpublished`
      }
    }
  }

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: "brave-pitch-deck",
    pathResolver,
  })

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData
      navigate(path)
    }
  }, [previewData, path])

  return (
    <StyledPreview>
      <div>
        <Spinner />
        Loading preview...
      </div>
    </StyledPreview>
  )
}

export default PreviewPage
